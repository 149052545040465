import React from 'react'
import Layout from '../components/layout'
import pdf from '../assets/baby-pack/pdf.svg'

class Resources extends React.Component {
  render() {
    return (
      <Layout>
        <h2 className="w-10/12 mx-auto mt-4 text-2xl font-bold text-center text-gray-800 font-muli">
          Voici des ressources que je mets en libre service
        </h2>
        <div id="free-resources" className="flex justify-center w-10/12 mx-auto mt-6 space-x-4">
          <a
            href="/resources/preconisations_journalieres_du_sommeil_selon_age.pdf"
            target="_blank"
            id="preconisation-journalieres"
            className="flex flex-col items-center justify-start w-32 text-center"
          >
            <img src={pdf} className="h-16" />
            <p>Préconisation journalières du sommeil</p>
          </a>
          <a
            href="/resources/securite_du_sommeil_0 _1_an.pdf"
            target="_blank"
            id="securite-sommeil"
            className="flex flex-col items-center justify-start w-32 text-center"
          >
            <img src={pdf} className="h-16" />
            <p>Sécurité du sommeil</p>
          </a>
        </div>
      </Layout>
    )
  }
}

export default Resources
